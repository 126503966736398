import React from "react";
import { SanityJotform } from "@graphql-types";
// @ts-ignore
import Jotform from "react-jotform-embed";
import { Container, GridColumnSpan, GridContainer } from "@util/standard";
import { BlocksContent } from "@global";

interface Props {
  data: SanityJotform;
}

export default function JotformEmbed({ data }: Props) {
  if (!data?.formSrc) return null;

  if (data.infoText) {
    return (
      <Container width="90%" margin="30px auto">
      <GridContainer
        repeat={6}
        mobileRepeat={1}
        tabletRepeat={1}
        columnGap={20}
        rowGap={10}
      >
        <GridColumnSpan gridColumnWidth={2}>
          <BlocksContent blocks={data.infoText._rawBlocks} />
        </GridColumnSpan>
          <GridColumnSpan gridColumnWidth={4} width={100}>
            <Jotform src={data.formSrc} />
          </GridColumnSpan>
      </GridContainer>
      </Container>
    );
  }

  return <Jotform src={data.formSrc} />;
}
