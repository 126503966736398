import { ErrorMsg, BlocksContent, Image, Video, Html, Link } from "@global";
import { Maybe } from "@graphql-types";
import GalleryCarousel from "@shared/galleryCarousel";
import {
  colorsRGB,
  colors,
  fontWeights,
  fontSizes,
  MOBILE_BREAKPOINT,
  TABLET_BREAKPOINT,
  IPAD_PRO_BREAKPOINT,
  IPAD_BREAKPOINT,
} from "@util/constants";
import { Container, P } from "@util/standard";
import {
  isSanityImageTextBlock,
  isSanityGallery,
  isSanityImageGrid,
  isSanityTextCta,
  isSanityHtml,
  isSanityVideo,
  isSanityFullWidthTextBlock,
  isSanityImageWithMeta,
  isJotform
} from "@util/types";
import React from "react";
import styled from "styled-components";
import JotformEmbed from "@shared/jotformEmbed";

interface Props {
  data: any;
}

const PageComponents = ({ data }: Props) => {
  if (!data) return null;

  if (isJotform(data)) {
    return <JotformEmbed data={data} />;
  }

  if (isSanityFullWidthTextBlock(data)) {
    return (
      <BlocksContentContainer
        applyBackground={data?.applyBackground}
        key={data?._key}
      >
        <BlocksContent blocks={data?.textBlock?._rawBlocks} />
      </BlocksContentContainer>
    );
  }
  if (isSanityImageTextBlock(data)) {
    return (
      <ImageTextBlockContainer
        key={data?._key}
        reverse={data?.reverse}
        backgroundColour={data?.backgroundColor?.value}
      >
        <ImageContainer reverse={data?.reverse}>
          <Image imageData={data?.image?.asset?.gatsbyImageData} />
        </ImageContainer>
        <ContentBlocksContainer reverse={data?.reverse}>
          <BlocksContent blocks={data?.text?._rawBlocks} />
        </ContentBlocksContainer>
      </ImageTextBlockContainer>
    );
  }
  if (isSanityImageWithMeta(data)) {
    return (
      <Container
        margin="2% auto 2% auto"
        height="100%"
        width="45%"
        key={data?._key}
      >
        <Image imageData={data?.asset?.gatsbyImageData} />
      </Container>
    );
  }
  if (isSanityImageGrid(data)) {
    return (
      <Container
        isMobileColumn={true}
        key={data?._key}
        justifyContent="center"
        flexWrap="wrap"
        flexGrow={1}
      >
        {data?.images?.map(image => {
          if (image == null) {
            return <P color="errorRed">Error loading content</P>;
          }
          return (
            <Image
              width={
                data?.images?.length == 2 || data?.images?.length == 4
                  ? "calc(50% - 20px)"
                  : data?.images?.length == 1
                  ? "calc(100% - 20px)"
                  : "calc(33% - 20px)"
              }
              height="100%"
              mobWidth="100%"
              objectFit="cover"
              margin="10px"
              imageData={image?.asset?.gatsbyImageData}
              key={data?._key}
            />
          );
        })}
      </Container>
    );
  }
  if (isSanityGallery(data)) {
    return <GalleryCarousel content={data} key={data?._key} />;
  }
  if (isSanityVideo(data)) {
    return <Video videoSrcURL={data.url} />;
  }
  if (isSanityHtml(data)) {
    return <Html content={data} key={data?._key} />;
  }
  if (isSanityTextCta(data)) {
    return (
      <CtaContainer className={`type-${data.type ?? "default"}`}>
        {data?.textCtas?.map(cta => {
          if (cta == null) {
            return <P color="errorRed">Error loading content</P>;
          }
          const gridClass =
            cta?.colWidth == null ? "full--column" : cta?.colWidth;

          return (
            <CtaRow
              className={
                "cta-card " +
                gridClass +
                (cta?.backgroundImage?.asset?.gatsbyImageData
                  ? ""
                  : " no-image")
              }
              key={cta?._key}
            >
              <span>{cta?.title}</span>
              <BlocksContent blocks={cta?.content?._rawBlocks} />
              {cta?.link?.url && cta?.link?.linktext ? (
                <Link
                  className="cta--btn"
                  linktext={cta?.link?.linktext}
                  url={cta?.link?.url}
                  internallink={cta?.link?.internallink}
                  newwindow={cta?.link?.newwindow}
                />
              ) : null}

              {cta?.backgroundImage?.asset?.gatsbyImageData && (
                <div className="cta-image-wrapper">
                  {cta?.backgroundImage &&
                    cta?.backgroundImage.asset?.gatsbyImageData && (
                      <Image
                        width="100%"
                        height="100%"
                        imageData={cta?.backgroundImage.asset?.gatsbyImageData}
                        objectFit="cover"
                      />
                    )}
                </div>
              )}
            </CtaRow>
          );
        })}
      </CtaContainer>
    );
  }
  return <ErrorMsg data={data} msg="Error loading section" />;
};

export default PageComponents;

export const BlocksContentContainer = styled(Container)<{
  applyBackground?: Maybe<boolean> | undefined;
}>`
  background-color: ${props =>
    props.applyBackground ? `${colors.lightGrayBackground}` : "transparent"};
  ${({ applyBackground }) => applyBackground && `padding:5% 0;`};
  margin: 2% auto 3% auto;
  width: 100%;
  h2,
  h3,
  h1,
  h5,
  h4,
  ul,
  ol,
  p {
    width: 65%;
    margin: auto;
    margin-bottom: 24px;
  }
  h2,
  h4,
  ul {
    margin-bottom: 1%;
  }
  @media only screen and (max-width: ${TABLET_BREAKPOINT}px) {
    margin: 5% auto 3% auto;
  }
  @media only screen and (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 9% auto 5% auto;
  }
`;

const ImageTextBlockContainer = styled(Container)<{
  reverse?: Maybe<boolean> | undefined;
  backgroundColour?: Maybe<string> | undefined;
}>`
  width: 90%;
  margin: auto;
  flex-direction: ${props => (props.reverse ? "row-reverse" : "row")};
  background-color: ${props => props.backgroundColour};
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    flex-direction: column-reverse;
  }
`;

const ImageContainer = styled(Container)<{
  reverse?: Maybe<boolean> | undefined;
}>`
  width: ${props => (props.reverse ? "50%" : "45%")};
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 90%;
    margin: auto;
  }
`;

const ContentBlocksContainer = styled(Container)<{
  reverse?: Maybe<boolean> | undefined;
}>`
  width: 45%;
  margin: auto;
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    width: 90%;
    margin: auto;
  }
`;

const CtaContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 1rem;
  margin: 15px 20px 15px 20px;
  @media only screen and (max-width: ${IPAD_BREAKPOINT}px) {
    margin-top: 10px;
    margin-bottom: 40px;
    grid-gap: 10px;
  }

  &.type-imageContent {
    margin: 20px 50px;
    gap: 30px;

    .cta-card {
      padding: 180px 20px 20px;
      //background-color: transparent;
      min-height: unset;
      height: fit-content;

      &.no-image {
        padding-top: 40px;
      }

      .block-content * {
        width: 100%;
        text-align: center;
      }

      .cta-image-wrapper {
        width: 150px;
        height: 150px;
        left: 50%;
        opacity: 1;
        transform: translateX(-50%);
        border-radius: 50%;
        overflow: hidden;
        top: 20px;
      }
    }
  }
`;
const CtaRow = styled.div<{ backgroundImage?: string }>`
  min-height: 250px;
  background-color: ${colorsRGB.merlin(0.04)};
  ${({ backgroundImage }) =>
    backgroundImage && `background-image: url(${backgroundImage});`}
  background-size: cover;
  background-position: center;
  padding: 60px;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.half--column {
    grid-column: span 3;
  }
  &.one-third--column {
    grid-column: span 2;
  }
  &.full--column {
    grid-column: span 6;
  }
  span {
    font-weight: ${fontWeights.bold};
  }
  .cta-image-wrapper {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;
    opacity: 0.3;
  }
  .cta--btn {
    &:hover {
      opacity: 1;
    }
    p {
      font-size: ${fontSizes.p.alternate}px;

      letter-spacing: 0.08em;
      border: 1.5px solid ${colors.merlin};
      border-radius: 36px;
      font-weight: ${fontWeights.bold};
      padding: 14px 40px;
      width: fit-content;
      margin: 15px auto 0;
      &:hover {
        background-color: ${colors.merlin};
        color: ${colors.white};
        text-decoration: none;
      }
    }
  }
  @media only screen and (max-width: ${IPAD_PRO_BREAKPOINT}px) {
    &.half--column,
    &.one-third--column {
      grid-column: span 6;
    }
  }
  @media only screen and (max-width: ${IPAD_BREAKPOINT}px) {
    padding: 40px;
    .cta--btn p {
      font-size: ${fontSizes.button.mobile}px;
      padding: 11px 25px;
    }
  }
`;
